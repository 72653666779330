<template>
  <v-card
    :loading="loading"
    flat
  >
    <v-card-title>
      <span>{{ fieldset.title }}</span>
      <span
        class="font-italic font-weight-regular text-lowercase"
      >{{ fieldset.recursive ? ` - ${$t('recursive')}` : '' }}</span>
    </v-card-title>

    <v-card-text>
      <DynamicFormContent
        :loading="loading"
        :fields="availableFields"
        :activate-field-check="propagation && fieldset.recursive"
        activate-field-icon="mdi-arrow-down-bold-box"
        class="pa-0"
      ></DynamicFormContent>
    </v-card-text>
  </v-card>
</template>

<script>
  import DynamicFormContent from '@/components/DynamicFormContent'

  export default {
    name: 'FieldsetForm',

    components: {
      DynamicFormContent,
    },

    props: {
      loading: {
        type: Boolean,
        default: false
      },

      fieldset: {
        type: Object,
        default: () => ({})
      },

      availableFields: {
        type: Array,
        default: () => []
      },

      propagation: {
        type: Boolean,
        default: false
      },
    },
  }
</script>
