<template>
  <v-card
    :loading="loading"
    flat
  >
    <v-card-title
      v-if="hasRecursiveFieldset"
      class="pa-0"
    >
      <v-spacer></v-spacer>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
          >
            <v-switch
              v-model="activatePropagation"
              :label="$t('propagation')"
              class="ma-0"
              inset
              dense
            ></v-switch>
          </span>
        </template>

        <span>{{ $t('propagation_tooltip') }}</span>
      </v-tooltip>
    </v-card-title>

    <v-form
      @submit.prevent="confirmModal"
      ref="form"
    >
      <FieldsetForm
        v-for="({enabler, fields}, index) in enablers"
        :key="index"
        :fieldset="enabler"
        :available-fields="fields"
        :propagation="activatePropagation"
      />

      <v-card-actions
        v-if="enablers.length > 0"
      >
        <v-btn
          :loading="loading"
          color="primary"
          type="submit"
        >
          <span>{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-form>

    <DefaultModal
      @submit="save"
      :title="$t('confirm_save')"
      ref="confirmModal"
    >
      <template v-slot:content>
        <span>{{ $t('comfirm_save_metadata') }}</span>

        <v-spacer></v-spacer>

        <v-alert
          v-if="propagationFieldsLabels.length > 0 && activatePropagation"
          type="warning"
          outlined
        >
          <span>{{ $t('propate_fields_alert') }}</span>

          <ul>
            <li v-for="label in propagationFieldsLabels" :key="label">
              <span class="font-weight-bold">{{ label }}</span>
            </li>
          </ul>
        </v-alert>
      </template>
    </DefaultModal>
  </v-card>
</template>

<script>
  import { mapActions } from 'vuex'

  import AppActions from '@/store/app/actions-types'
  import EnablerActions from '@/store/operation/enabler/actions-types'
  import FieldActions from '@/store/node/field/actions-types'

  import DefaultModal from '@/components/DefaultModal'
  import FieldsetForm from '@/components/form/FieldsetForm'

  export default {
    name: 'NodeFieldsets',

    components: {
      DefaultModal,
      FieldsetForm,
    },

    props: {
      node: {
        type: Object,
        default: () => ({})
      },
    },

    data() {
      return {
        loading: false,
        activatePropagation: false,
        enablers: [],
        existingFields: [],
        formData: {},
        fieldsToPropagate: [],
        propagationFieldsLabels: [],
      }
    },

    watch: {
      node() {
        this.fetchData()
      }
    },

    computed: {
      hasRecursiveFieldset() {
        if (this.enablers.length === 0) {
          return false
        }

        return this.enablers.some(enabler => enabler.enabler.recursive) && this.node.type === 'folder'
      }
    },

    methods: {
      ...mapActions('app', [
        AppActions.OPEN_APP_SUCCESS_MESSAGE,
      ]),

      ...mapActions('operation/enabler', [
        EnablerActions.GET_ENABLERS_BY_NODE,
      ]),

      ...mapActions('node/field', [
        FieldActions.GET_NODE_FIELD_VALUES,
        FieldActions.SAVE_FORM_VALUES,
      ]),

      fetchData() {
        this.loading = true

        const options = {
          order: 'aspect_key',
          direction: 'ASC',
          'filter[type]': "fieldset",
        }

        this[EnablerActions.GET_ENABLERS_BY_NODE]({nodeId: this.node.node_id, options})
          .then(enablers => {
            this[FieldActions.GET_NODE_FIELD_VALUES](this.node.node_id)
              .then((fieldValues) => {
                this.enablers = [
                  ...enablers.items.map(enabler => {
                    let fields = this.getAvailableFields(enabler)

                    fields = this.fillFieldValues(fields, fieldValues)

                    return {
                      enabler,
                      fields
                    }
                  })
                ]
              })
          })
          .finally(() => {
            this.loading = false
          })
      },

      getAvailableFields(enabler) {
        let enablerFields = JSON.parse(enabler.enabler_params || "{}").fields || []

        enablerFields = [...enablerFields.filter(field => {
          return false === this.existingFields.includes(field.name)
        })]

        this.existingFields = [...this.existingFields, ...enablerFields.map(field => field.name)]

        return enablerFields
      },

      fillFieldValues(fields, fieldValues) {
        fields.forEach((field) => {
          const value = fieldValues.find(value => value.field_key === field.name)

          if (value) {
            field.value = value.value
          }
        })

        return fields
      },

      confirmModal() {
        if (! this.$refs.form.validate()) {
          return
        }

        this.propagationFieldsLabels = [...[]]
        this.fieldsToPropagate = [...[]]

        this.formData = new FormData(this.$refs.form.$el)

        this.fieldsToPropagate = [...this.formData.getAll('field_check[]')
          .reduce((propagation, fields) => {
            return [...propagation, ...fields.split(',')]
          }, [])]

        this.enablers.forEach(enabler => {
          this.propagationFieldsLabels = enabler.fields.reduce((acc, field) => {
            if (this.fieldsToPropagate.includes(field.name)) {
              return [...acc, field.label]
            }

            return acc
          }, this.propagationFieldsLabels)
        })

        this.$refs.confirmModal.openModal()
      },

      save() {
        if (! this.$refs.form.validate()) {
          return
        }

        this.loading = true

        const data = this.buildPostData()

        this[FieldActions.SAVE_FORM_VALUES]({ nodeId: this.node.node_id, data })
          .then(() => {
            this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('metadata_saved_successfully'))
          })
          .finally(() => {
            this.$refs.confirmModal.closeModal()
            this.loading = false
          })
      },

      buildPostData() {
        let postData = {
          has_propagation: this.activatePropagation,
          fieldsets: []
        }

        this.enablers.forEach(element => {
          const fieldset = element.enabler
          const fields = element.fields

          postData.fieldsets.push({
            fieldset_id: fieldset.enabler_id,
            fields: fields.map(field => {
              return {
                field_key: field.name,
                field_value: this.formData.get(field.name) || "",
                propagate: this.activatePropagation ? this.fieldsToPropagate.includes(field.name) : false
              }
            })
          })
        })

        return postData
      },
    }
  }
</script>
