<template>
  <ActionViewContainer
    :key="componentKey"
    title="information"
    title-icon="mdi-information-outline"
    :entryData="entryData"
    :breadcrumbsItems="formattedBreadcrumbs"
    @move-success="forceReaload"
  >
    <template slot="table">
      <NodeFieldsets
        :node="entryData"
      />
    </template>
  </ActionViewContainer>
</template>

<script>

import { mapActions } from 'vuex'

import ActionViewContainer from '@/components/content/ActionViewContainer'
import NodeFieldsets from '@/components/form/NodeFieldsets.vue'

import EntryActions from '@/store/content/entry/actions-types'

export default {
  name: "EntryInfo",

  components: {
    ActionViewContainer,
    NodeFieldsets,
  },

  data() {
    return {
      loading: true,
      entryData: {},
      breadcrumb: [],
      componentKey: 0,
    }
  },
  computed: {
    formattedBreadcrumbs() {
      let formatted = [
        {
          prependIcon: 'mdi-home-outline',
          iconColor: 'primary',
          to: {
            name: 'ContentHome',
            params: { appId: this.$route.params.appId }
          },
          link: true,
          disabled: false,
          ripple: true,
          tooltipText: "Home",
        },
      ]

      const reversed = [].concat(this.breadcrumb).reverse()

      reversed.forEach((element, index, array) => {
        formatted.push({
          to: {
            name: 'ContentNavigation',
            params: { parentId: element.node_id }
          },
          text: element.name,
          ripple: true,
          disabled: Object.is(array.length - 1, index)
        })
      })

      return formatted
    },
  },

  mounted() {
    this.fetchData()
  },

  methods: {
    ...mapActions('content/entry', [
      EntryActions.LIST_SUBFOLDER,
      EntryActions.GET_ENTRY_DATA,
    ]),
    forceReaload() {
      this.fetchData()
      this.componentKey += 1
    },
    fetchData() {
      this.loading = true

      let nodeId = this.$route.params.nodeId

      this[EntryActions.LIST_SUBFOLDER]({parentId: nodeId, updateList: false})
        .then((result) => {
          this.breadcrumb = [...result.breadcrumb]
        })
        .finally(() => this.loading = false)

      this[EntryActions.GET_ENTRY_DATA](nodeId)
        .then((data) => {
          this.entryData = data
        })
        .finally(() => this.loading = false)
    },
  },
}

</script>
